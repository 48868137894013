<template>
    <span v-if="!loading" >
        <v-col>
            <v-row class="mx-2 my-2">
                    <v-card  width="100%">
                        <span class="d-flex justify-start ma-2 color-primary font-size14">Cpt Code 99453</span>
                        <v-card class="px-4 py-2 elevation-0 font-size14" v-if="details?.cptCode99453">
                            <v-row dense class="d-flex justify-space-between ">
                                <span>{{ $t("device") }}</span>
                                <span class="font-size14 font-weight-bold">{{ details.cptCode99453.deviceTypeTitle }}</span>
                            </v-row>
                          <!-- <v-row dense>
                              <v-col cols="1" class="col-left-alignment">
                                  Identifier
                              </v-col>
                              <v-col class="col-left-alignment">
                                  {{ details.cptCode99453.deviceIdentifier }}
                              </v-col>
                          </v-row> -->
                            <v-row dense class="d-flex justify-space-between ">
                                <span>{{ $t("generated") }}</span>
                                <span class="font-size14 font-weight-bold">{{ details.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone)}}</span>
                            </v-row>
                        </v-card>
                    </v-card>
            </v-row>
        </v-col>
      <v-row dense class="px-5">
        <v-col cols="6">
        <v-card v-if="item.cptCode99454" width="100%">
             <span class="d-flex justify-start px-2  color-primary font-size14 mb-3 mt-2">Cpt Code 99454</span>
                            <v-card class="px-4 py-2 elevation-0 font-size14" v-if="item?.cptCode99454"  >
                                <v-row dense class="d-flex justify-space-between ">
                                    <span > {{ $t("measurements") }}</span>
                                    <span class="font-size14 font-weight-bold">{{ item.cptCode99454.totalMeasurements }}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between">
                                    <span> {{ $t("generated") }}</span>
                                    <span class="font-size14 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>

        <v-col cols="6">
        <v-card width="100%" >
                            <span class="d-flex justify-start ma-2 color-primary font-size14">Cpt Code 99457</span>
                            <v-card class="px-4 py-2 elevation-0 font-size14" v-if="item">
                                <v-row dense class="d-flex justify-space-between " >
                                    <span class="font-size-small2">{{ $t("minutes") }}</span>
                                    <span class="font-size14 font-weight-bold" v-if="item?.cptCode99457">{{secToMinV2(item?.cptCode99457.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size-small2">{{ $t("generated") }}</span>
                                    <span class="font-size14 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>
      </v-row>
         <v-row dense class="px-5 mt-2">
        <v-col cols="6">
        <v-card  width="100%" >
                            <span class="d-flex justify-start ma-2 color-primary font-size14">Cpt Code 99458 First</span>
                            <v-card class="px-4 py-2 elevation-0 font-size14" v-if="item?.cptCode99458First">
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size-small2">{{ $t("minutes") }}</span>
                                    <span class="font-size14 font-weight-bold">{{secToMinV2( item.cptCode99458First.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense  class="d-flex justify-space-between ">
                                    <span class="font-size-small2">{{ $t("generated") }}</span>
                                    <span class="font-size14 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone) }}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>

        <v-col cols="6">
         <v-card  width="100%" >
                            <span class="d-flex justify-start ma-2 color-primary font-size14">Cpt Code 99458 Second</span>
                            <v-card class="px-4 py-2 elevation-0 font-size14" v-if="item?.cptCode99458Second">
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size-small2">{{ $t("minutes") }}</span>
                                    <span class="font-size14 font-weight-bold">{{secToMinV2( item.cptCode99458Second.totalIntervalInSeconds)}}</span>
                                </v-row>
                                <v-row dense class="d-flex justify-space-between ">
                                    <span class="font-size-small2">{{ $t("generated") }}</span>
                                    <span class="font-size14 font-weight-bold">{{ item.generatedAt | dateformat("timestamp_med", timeLocalization, userDetails.userTimezone)}}</span>
                                </v-row>
                            </v-card>
                        </v-card>
        </v-col>
      </v-row>

        <v-row class="mx-2">
            <v-col cols="6">
                <v-text-field
                    v-if="item.cptCode99454"
                    class=" my-1 px-2 elevation-2"
                    v-model="search99454"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense
                    single-line
                    hide-details
                ></v-text-field>
                <v-data-table
                    v-if="item.cptCode99454"
                    :loading="loading"
                    :headers="cptCode99454Headers"
                    :no-data-text="$t('no-data-available')"
                    :loading-text="$t('loading')"
                    :headers-length="cptCode99454Headers.length"
                    :search="search99454"
                    :items="cptCode99454WithDetails"
                    :items-per-page="-1"
                    sort-by="id"
                    item-key="id"
                    fixed-header
                    dense
                    class="elevation-2"
                    style="cursor: pointer; overflow-y: auto;" width="100%" height="300"
                    :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                >
                    <template v-slot:[`item.measuredIntervalFrom`]="{ item }">
                        {{ item.measuredIntervalFrom | dateformat("datetime_short_with_seconds", timeLocalization) }}
                    </template>

                </v-data-table>
              </v-col>
                  <v-col cols="6">
                    <v-text-field
                        class="my-1 px-2 elevation-2"
                        v-model="search99457"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        dense
                        hide-details
                    ></v-text-field>
                    <v-data-table
                        :loading="loading"
                        :no-data-text="$t('no-data-available')"
                        :loading-text="$t('loading')"
                        :headers="cptCode99457Headers"
                        :headers-length="cptCode99457Headers.length"
                        :search="search99457"
                        :items="cptCode99457WithDetails"
                        :items-per-page="-1"
                        :item-key="'refAppointmentId'"
                        dense
                        fixed-header
                        class="elevation-1 my-1"
                        style="cursor: pointer; overflow-y: auto;" width="100%" height="300"
                        :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                    >
                        <template v-slot:[`item.eventStartedAt`]="{ item }">
                            {{ item.eventStartedAt | dateformat("datetime_short_with_seconds", timeLocalization) }}
                        </template>
                        <template v-slot:[`item.eventEndedAt`]="{ item }">
                            {{ item.eventEndedAt | dateformat("datetime_short_with_seconds", timeLocalization) }}
                        </template>
                      <template v-slot:[`item.duration`]="{ item }">
                      {{ calculateDuration(item.eventStartedAt, item.eventEndedAt) }}
                    </template>
                    </v-data-table>
            </v-col>
        </v-row>
    </span>
</template>
<script>
import { mapState } from 'vuex';

export default {
	props: ['item'],
	data () {
		return {
			itemsType: [],
			loading: false,
			search99457: null,
			search99454: null,
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			bilingItemType: state => state.billings.billingDetailsForPatientForMonth,
			details: state => state.billings.billingDetailsForPatientForMonth?.billingHeaderDetailsData,
			userDetails: (state) => state.authentication.userData,
		}),
		cptCode99454WithDetails () {
			return this.details?.cptCode99454WithDetails?.details;
		},
		cptCode99457WithDetails () {
			const data = [];
			var dataItem = {};
			if (this.details?.cptCode99457WithDetails) {
				this.details.cptCode99457WithDetails.details9945799458.forEach(element => {
					var itemType = this.itemsType[element.billingItemType - 1];
					dataItem = {
						billingItemType: itemType,
						measurementDeviceTypeTitle: element.measurementDeviceTypeTitle,
						measuredIntervalFrom: element.measuredIntervalFrom,
						eventStartedAt: element.eventStartedAt,
						eventEndedAt: element.eventEndedAt,
					};
					data.push(dataItem);
				});
			}
			return data;
		},
		cptCode99454Headers () {
			return [
				// {
				// 	text: 'Id',
				// 	value: 'billingReferenceId',
				// },
				// {
				// 	text: 'Item type',
				// 	value: 'billingItemType',
				// },
				{
					text: this.$t('device'),
					value: 'measurementDeviceTypeTitle',
				},
				{
					text: this.$t('time'),
					value: 'measuredIntervalFrom',
				},
			];
		},
		cptCode99457Headers () {
			return [
				// {
				// 	text: 'Id',
				// 	value: 'billingReferenceId',
				// },
				{
					text: this.$t('item-type'),
					value: 'billingItemType',
				},
				{
					text: this.$t('started'),
					value: 'eventStartedAt',
				},
				{
					text: this.$t('ended'),
					value: 'eventEndedAt',
				},
        {
          text: this.$t('duration'),
          value: 'duration',
        },
			];
		},
	},
	watch: {
		item: {
			immediate: true,
			deep: true,
			async handler (value) {
				// await this.initialize({ patientId: value.patientId, month: value.billingMonth, year: new Date().getUTCFullYear() });
                await this.initialize({ patientId: value.patientId, month: value.billingMonth, year: value.billingYear });
				this.loading = false;
				this.getType();
			},
		},
	},
	// created () {
	// 	this.getType();
	// },
	methods: {
    calculateDuration (startTime, endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const durationInMilliseconds = end - start;

      // Convert duration to hours, minutes, and seconds
      const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
      const seconds = Math.floor((durationInMilliseconds % 60000) / 1000);

      // Format the duration string
      return `${minutes}m ${seconds}s`;
    },
        secToMinV2 (seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;

            return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
        },
		async initialize (headers) {
			await this.$store.dispatch('billings/getbillingdetailsforpatientformonth', headers);
		},
		getType () {
			if (this.bilingItemType?.billingItemTypes) {
				const count = Object.keys(this.bilingItemType?.billingItemTypes).length;
				for (let i = 1; i <= count; i++) {
					this.itemsType.push(this.bilingItemType?.billingItemTypes?.[i]);
				}
			}
		},
	},
};
</script>
